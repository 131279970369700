import {
  styled,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import dayjs from "dayjs";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { useEffect, useState } from "react";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "darkgrey",
    color: theme.palette.common.white,
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 1,
  },
}));

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 1.05076),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

export const PriceTable = ({ data = rows }) => {
  const [highest, setHighest] = useState({});
  const [lowest, setLowest] = useState({});
  useEffect(() => {
    if (data.length !== 0) {
      const high = data.reduce((prev, current) => {
        return prev.val > current.val ? prev : current;
      });
      setHighest(high);
      const low = data.reduce((prev, current) => {
        return prev.val < current.val ? prev : current;
      });
      setLowest(low);
    }
  }, []);

  const rowBg = (item) => {
    if (item === highest) return "#FF0000";
    if (item === lowest) return "#33cc33";
    return "";
  };
  return (
    <TableContainer sx={{ width: "80vw" }} component={Paper}>
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell sx={{ width: "50%" }} align="center">
              Date
            </StyledTableCell>
            <StyledTableCell align="center">Price</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <StyledTableRow
              style={{ backgroundColor: rowBg(row) }}
              key={row.nav_date}
            >
              <StyledTableCell component="th" scope="row" align="center">
                {dayjs(row.nav_date).format("YYYY MMMM DD")}
              </StyledTableCell>
              <StyledTableCell align="center">
                {formatUnitByCurr(row.val,row.currency)}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

function formatUnit(angka) {
  const formatter = new Intl.NumberFormat("de-DE", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const result = formatter.format(angka);

  return result;
}

function formatUnitByCurr(angka, curr) {
  const formatter = new Intl.NumberFormat("de-DE", {
    minimumFractionDigits: curr == "USD" ? 5 : 2,
    maximumFractionDigits: curr == "USD" ? 5 : 2,
  });
  const result = formatter.format(angka);

  return result;
}
