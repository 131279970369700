import React, { useEffect, useState } from "react";
import Chart from "chart.js/auto";
import "chartjs-adapter-moment";

function sampleArray(arr) {
  const sampleSize = 75;
  if (arr.length <= sampleSize) {
    return arr;
  }
  const sampledArray = [];
  const interval = Math.floor(arr.length / sampleSize);
  for (let i = 0; i < arr.length; i += interval) {
    sampledArray.push(arr[i]);
  }
  if (sampledArray[sampledArray.length - 1] !== arr[arr.length - 1]) {
    sampledArray.push(arr[arr.length - 1]);
  }
  return sampledArray;
}

function sortDataPoints(dataPoints) {
  dataPoints.sort((a, b) => {
    return new Date(a.nav_date) - new Date(b.nav_date);
  });
  return dataPoints;
}

export const PriceChart = ({ dataPoints }) => {
  useEffect(() => {
    dataPoints = sampleArray(dataPoints);
    dataPoints = sortDataPoints(dataPoints);

    const downSampledData = {
      data: dataPoints.map((point) => parseFloat(point.val)),
      minValue: Math.min(
        ...dataPoints.map((point) => parseFloat(point.val))
      ),
      maxValue: Math.max(
        ...dataPoints.map((point) => parseFloat(point.val))
      ),
    }

    let labels = dataPoints.map((point) => point.nav_date);

    const ctx = document.getElementById("priceChart");
    if (!ctx) return;

    const maxPrice = downSampledData.maxValue;
    const minPrice = downSampledData.minValue;

    const plugin = {
      id: "customCanvasBackgroundColor",
      beforeDraw: (chart, args, options) => {
        const { ctx } = chart;
        ctx.save();
        ctx.globalCompositeOperation = "destination-over";
        ctx.fillStyle = options.color || "#99ffff";
        ctx.fillRect(0, 0, chart.width, chart.height);
        ctx.restore();
      },
    };

    const fundChart = new Chart(ctx, {
      type: "line",
      data: {
        labels,
        datasets: [
          {
            label: "Price",
            data: downSampledData.data,
            borderColor: "red",
            backgroundColor: "rgba(0, 116, 217, 0.2)",
            pointBackgroundColor: (context) => {
              const price = context.parsed.y;
              return price === maxPrice
                ? "#FF4136"
                : price === minPrice
                ? "#2ECC40"
                : "white";
            },
            pointRadius: (context) => {
              const price = context.parsed.y;
              return price === maxPrice || price === minPrice ? 4 : 1;
            },
            pointHoverRadius: 6,
            borderWidth: 2,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            type: "timeseries",
            offset: true,
            bounds: "ticks",
            ticks: {
              source: "auto",
              // Disabled rotation for performance
              maxRotation: 0,
              autoSkip: true,
              maxTicksLimit: 16,
            },
            time: {
              displayFormats: {
                hour: "DD MMM",
                day: "DD MMM",
                month: "MMM YYYY",
                year: "MMM YYYY",
              },
            },
            title: {
              display: true,
              text: "Date",
            },
          },
          y: {
            type: "linear",
            display: true,
            title: {
              display: true,
              text: "Price",
            },
            ticks: {
              maxTicksLimit: 10,
            },
          },
        },
        plugins: {
          customCanvasBackgroundColor: {
            color: "white",
          },
          legend: {
            display: true,
            labels: {
              generateLabels: (chart) => {
                const labels =
                  Chart.defaults.plugins.legend.labels.generateLabels(chart);
                labels.forEach((label) => {
                  if (label.text === "max")
                    label.fillStyle = "#FF4136"; // Max price color (red)
                  else if (label.text === "min")
                    label.fillStyle = "#2ECC40"; // Min price color (green)
                  else label.fillStyle = "red"; // Common price color (blue)
                });
                return labels;
              },
            },
          },
        },
      },
      plugins: [plugin],
    });

    return () => {
      fundChart.destroy();
    };
  }, [dataPoints]);

  const image = require(`../assest/images/legend.png`);
  return (
    <div style={{ padding: 10, height: "345px", width: "80vw" }}>
      <canvas
        style={{
          border: "1px solid #dd",
          borderRadius: "8px",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        }}
        id="priceChart"
      />
      <img style={{marginLeft:"auto", marginRight:"auto", display:"block", marginTop:"7px"}} src={image}></img>
    </div>
  );
};
