import {
  Table,
  TableBody,
  TableContainer,
  TableRow,
  Paper,
} from "@mui/material";
import dayjs from "dayjs";
import "../index.css";

export const DailyUnitlinkTable = ({ data }) => {
  return (
    <TableContainer component={Paper}>
      <Table
        class="datatable2"
        width="100%"
        bgcolor="#bebdbd"
        cellspacing="1"
        cellpadding="5"
        style={{
          border: "1px solid #d5d5d5",
          backgroundColor: "#818286",
          fontSize: "15px",
          color: "#68737A",
        }}
      >
        <TableBody>
          <TableRow
            style={{
              display: "table-row",
              verticalAlign: "inherit",
              borderColor: "inherit",
              backgroundColor: "#818286",
              color: "#fff",
              fontWeight: "bold",
            }}
          >
            <td rowspan="2" align="center" valign="middle">
              Nama Dana
            </td>
            <td rowspan="2" align="center" valign="middle">
              Mata Uang
            </td>
            <td align="center" valign="middle">
              Nilai Aktiva Bersih
            </td>
            <td align="center" valign="middle">
              Total Unit
            </td>
            <td align="center">Harga *</td>
            <td colspan="6" align="center">
              Kinerja Investasi
            </td>
          </TableRow>
          <tr style={{ backgroundColor: "#E3E3E3", color: "#666666" }}>
            <td align="center" colspan="3">
              {dayjs(data[0]?.nav_date).format("DD MMM YYYY")}
            </td>
            <td align="center">1Mgg</td>
            <td align="center">1Bln</td>
            <td align="center">3Bln</td>
            <td align="center">6Bln</td>
            <td align="center">YTD</td>
            <td align="center">1Thn</td>
          </tr>

          {data.map((row, index) => (
            <tr key={index} bgcolor="#FFFFFF">
              <td>
                <span style={{ color: "#ED1B2E", fontWeight: "bold" }}>
                  PRU
                </span>
                <span style={{ color: "#000" }}>link</span>{" "}
                {row?.fund_name?.replace("PRULink ", "")}
              </td>
              <td align="center">{row.currency}</td>
              <td align="center">{formatUnit(row.nav)}</td>
              <td align="center">{formatUnit(row.total_unit)}</td>
              <td align="center">{formatUnitByCurr(row.val,row.currency)}</td>
              <td align="center">{row["1W"]?row["1W"]?.toFixed(2):"0"}%</td>
              <td align="center">{row["1M"]?row["1M"]?.toFixed(2):"0"}%</td>
              <td align="center">{row["3M"]?row["3M"]?.toFixed(2):"0"}%</td>
              <td align="center">{row["6M"]?row["6M"]?.toFixed(2):"0"}%</td>
              <td align="center">{row["YTD"]?row["YTD"]?.toFixed(2):"0"}%</td>
              <td align="center">{row["1Y"]?row["1Y"]?.toFixed(2):"0"}%</td>
            </tr>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

function formatUnit(angka) {
  const formatter = new Intl.NumberFormat("de-DE", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const result = formatter.format(angka);

  return result;
}

function formatUnitByCurr(angka, curr) {
  const formatter = new Intl.NumberFormat("de-DE", {
    minimumFractionDigits: curr == "USD" ? 5 : 2,
    maximumFractionDigits: curr == "USD" ? 5 : 2,
  });
  const result = formatter.format(angka);

  return result;
}