import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import "./index.css";

import { DailyUnitlinkTable } from "./components/DailyUnitlinkTable";
import { PriceChart } from "./components/PriceChart";
import { PriceTable } from "./components/PriceTable";

const API_URL = process.env.NODE_ENV === "production"?
  "https://polisku.prudential.co.id/fund-price":"https://polisku-uat.prudential.co.id/fund-price";

//const isSharia = window.location.hostname === "prudentialsyariah" ? true : false;

// for testing syariah
const stringParams = document.location.search;
const searchParams = new URLSearchParams(stringParams);
const isSharia = searchParams.get("isSharia") === "true" ? true : false;

const clientWidth = document.documentElement.clientWidth;

const truncateText = (text, maxLength) => {
  if (text.length > maxLength) {
    return text.slice(0, maxLength - 3) + "...";
  }
  return text;
};

export default function Root(props) {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedFund, setSelectedFund] = useState("");
  const [fundNames, setFundNames] = useState([]);
  const [fundData, setFundData] = useState([]);
  const [showChartTable, setShowChartTable] = useState(0); // 0 daily, 1 chart, 2 table
  const [errorMessage, setErrorMessage] = useState("");
  const [errorFund, setErrorFund] = useState("");

  useEffect(() => {
    window.document.title =
      "Daily Fund Price | Laporan Investasi | Prudential Indonesia";
    getDailyUnitLink();
    getFundNames();
  }, []);

  useEffect(() => {
    setErrorFund("");
  }, [startDate, endDate, fundNames]);

  const getDailyUnitLink = () => {
    fetch(API_URL + "/dailyUnitLink", {
      method: "POST",
      body: JSON.stringify({ isSharia }),
    })
      .then((response) => response.json())
      .then((data) => {
        setFundData(data.data);
      })
      .catch((error) => setErrorMessage("Terjadi kesalahan"));
  };

  const getFundNames = () => {
    fetch(API_URL + "/getFunds", {
      method: "POST",
      body: JSON.stringify({ isSharia }),
    })
      .then((response) => response.json())
      .then((data) => {
        setFundNames(data.data);
      })
      .catch((error) => setErrorMessage("Terjadi kesalahan"));
  };

  const handleSelectedFund = (event) => {
    setErrorFund("");
    setSelectedFund(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (selectedFund === "") {
      setErrorFund("No fund selected");
      return;
    }
    if (selectedFund === "all") {
      setShowChartTable(0);
      getDailyUnitLink();
      getFundNames();
      return;
    }
    fetch(API_URL + "/unitLinkDetail", {
      method: "POST",
      body: JSON.stringify({
        fundCode: selectedFund.fund_code,
        start: new Date(startDate)
          .toLocaleDateString("en-GB")
          .split("/")
          .reverse()
          .join("-"),
        end: new Date(endDate)
          .toLocaleDateString("en-GB")
          .split("/")
          .reverse()
          .join("-"),
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.resp_code !== 0) {
          setErrorFund(data?.resp_desc);
        } else if (data.data.length === 0) {
          alert("Tidak ada data");
        } else {
          setFundData(data.data);
          setShowChartTable(1);
        }
      })
      .catch((error) => setErrorFund("Terjadi kesalahan"));
  };

  const renderSelectFund = (item) => {
    if (clientWidth <= 360) return truncateText(item.fund_name, 40);
    if (clientWidth < 400) return truncateText(item.fund_name, 45);
    if (clientWidth < 450) return truncateText(item.fund_name, 50);
    else return item.fund_name;
  };

  return (
    <form className="form" onSubmit={handleSubmit}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          gap: 5,
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 2,
            }}
            components={["DatePicker"]}
          >
            <DatePicker
              label="Start From"
              format="YYYY MMM DD"
              onChange={(value) => {
                setStartDate(value);
              }}
              maxDate={dayjs(new Date())}
            />
            <p>-</p>
            <DatePicker
              disabled={startDate === ""}
              label="Until"
              format="YYYY MMM DD"
              onChange={(value) => {
                setEndDate(value);
              }}
              maxDate={dayjs(new Date())}
              minDate={startDate !== "" ? dayjs(startDate) : null}
            />
          </Box>
        </LocalizationProvider>
        <FormControl sx={{ width: "100%" }} size="medium">
          <InputLabel id="demo-select-small-label">Fund Name</InputLabel>
          <Select
            labelId="demo-select-small-label"
            value={selectedFund}
            label="Fund Name"
            onChange={handleSelectedFund}
          >
            <MenuItem value={"all"}>
              <em>All Fund</em>
            </MenuItem>
            {fundNames?.map((item, index) => (
              <MenuItem
                sx={{
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
                key={index}
                value={item}
              >
                <Typography variant="body2" noWrap>
                  {renderSelectFund(item)}
                </Typography>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {errorFund !== "" && (
          <Typography sx={{ color: "red", fontWeight: "italic" }}>
            {errorFund}
          </Typography>
        )}
        <button type="submit" className="submit-button">
          Go
        </button>
        {errorMessage !== "" && (
          <Typography sx={{ color: "red", fontWeight: "italic" }}>
            {errorMessage}
          </Typography>
        )}
      </Box>
      {showChartTable > 0 ? (
        <Box sx={{ width: "80vw" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Typography sx={{ fontWeight: "bold" }}>View Type: </Typography>
            <Typography
              className="view-type"
              sx={{
                textDecoration: showChartTable === 1 ? "underline" : "none",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
              onClick={() => setShowChartTable(1)}
            >
              Chart
            </Typography>
            |
            <Typography
              className="view-type"
              sx={{
                textDecoration: showChartTable === 2 ? "underline" : "none",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
              onClick={() => setShowChartTable(2)}
            >
              Table
            </Typography>
          </Box>
          <br />
          <table
            border="0"
            cellspacing="1"
            cellpadding="1"
            style={{ fontSize: "16px", alignSelf: "flex-start" }}
          >
            <tbody>
              <tr>
                <td width="120">
                  <b>Fund Name:</b>
                </td>
                <td style={{ color: "grey" }}>
                  <span style={{ color: "#ED1B2E", fontWeight: "bold" }}>
                    PRU
                  </span>
                  <span style={{ color: "#000" }}>link</span>{" "}
                  {selectedFund?.fund_name?.replace("PRULink ", "")}
                </td>
              </tr>
              <tr>
                <td width="120">
                  <b>Currency:</b>
                </td>
                <td style={{ color: "grey" }}>{selectedFund.currency}</td>
              </tr>
              <tr>
                <td width="120">
                  <b>Date Period:</b>
                </td>
                <td style={{ color: "grey" }}>
                  {dayjs(startDate).format("DD MMM YYYY")} to{" "}
                  {dayjs(endDate).format("DD MMM YYYY")}
                </td>
              </tr>
            </tbody>
          </table>
        </Box>
      ) : (
        <>
          {fundData?.length > 0 && showChartTable === 0 ? (
            <Typography sx={{ fontWeight: "bold" }}>
              Kinerja Investasi
            </Typography>
          ) : null}
        </>
      )}
      {fundData?.length > 0 && showChartTable === 0 ? (
        <DailyUnitlinkTable data={fundData} />
      ) : (
        <Box
          sx={{
            height:
              showChartTable < 0 || showChartTable > 2 || fundData?.length === 0
                ? "50vh"
                : "0vh",
          }}
        />
      )}
      {fundData?.length > 0 && showChartTable === 1 && (
        <PriceChart dataPoints={fundData} />
      )}
      {fundData?.length > 0 && showChartTable === 2 && (
        <>
          <PriceTable data={fundData} />
          <div style={{ display: "flex", alignItems: "center" }}>
            <span style={{ backgroundColor: "#FF0000", height: 20, width: 20 }}>
              &nbsp;&nbsp;&nbsp;
            </span>
            <p>&nbsp; : max price &nbsp;&nbsp;&nbsp;&nbsp;</p>
            <span style={{ backgroundColor: "#33cc33", height: 20, width: 20 }}>
              &nbsp;&nbsp;&nbsp;
            </span>
            <p>&nbsp; : min price &nbsp;&nbsp;&nbsp;&nbsp;</p>
          </div>
        </>
      )}
    </form>
  );
}
